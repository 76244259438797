import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import Logo from "../components/logo"
import locales from "../locales/index.esm"
import config from "../../siteconfig.esm"

const locale = process.env.GATSBY_LOCALE
const labels = locales[locale]

const Report = props => {
  const { quiz, nextQuiz, siteData } = props.pageContext
  let prepAnswers = props.location.state ? props.location.state.answers : []
  if (!prepAnswers.length && props.location.search) {
    // visitor has linked to the page so there is no state but there is a query string
    // so we'll construct state from the query params...
    let queryParams = props.location.search
      .slice(1)
      .split("&")
      .map(p => p.split("="))
    // const quizId = queryParams.slice(0, 1) // If we need to get the quiz record from siteData...
    queryParams = queryParams.slice(1)
    prepAnswers = queryParams.map((i, ix) => {
      const q = quiz.relationships.field_question.find(q => q.id === i[0])
      const a = q.relationships.field_answers.find(a => a.id === i[1])
      return { answer: a, question: q, index: ix ? q.field_question_order : 0 }
    })
  }

  const prepResources = props.pageContext.quiz.relationships.field_quiz_resource.reduce(
    (acc, i) => {
      if (!i.relationships.field_resource_category?.name) return acc
      if (acc[i.relationships.field_resource_category.name]) {
        acc[i.relationships.field_resource_category.name].push(i.field_resource_link)
      } else {
        acc[i.relationships.field_resource_category.name] = [i.field_resource_link]
      }
      return acc
    },
    {}
  )
  const prepResourceCategories = Object.keys(prepResources)

  const [answers] = useState(prepAnswers)
  const [resources] = useState(prepResources)
  const [resourceCategories] = useState(prepResourceCategories)

  return (
    <Layout siteData={siteData} seo={{ title: siteData.pageTitles.report }}>
      <PageHeader crumbs={props.pageContext.breadcrumb} />
      <Container className="report not-front">
        <h1>{labels["report.header"]}</h1>
        <h2>
          <Logo as="text" locale={locale} /> {quiz.title}&nbsp;{labels.report}
        </h2>
        <Row className="px-md-3">
          <Col md={8} xs={12} className="pe-md-5 pb-md-5">
            {answers.map(answer => {
              if (answer.answer.field_summary && answer.answer.field_plan) {
                return (
                  <Row key={answer.answer.id}>
                    <h3>{answer.question.field_question_report_title}</h3>
                    <h4>{labels["report.sub.summary"]}</h4>
                    <span
                      dangerouslySetInnerHTML={{ __html: answer.answer.field_summary.processed }}
                    ></span>
                    <h4>{labels["report.sub.action"]}</h4>
                    <span
                      dangerouslySetInnerHTML={{ __html: answer.answer.field_plan.processed }}
                    ></span>
                  </Row>
                )
              } else return <></>
            })}
            <Row>
              <h3>{labels.resources}</h3>
              {resourceCategories.map(cat => (
                <div key={cat}>
                  <h4>{cat}</h4>
                  <ul>
                    {resources[cat].map(res => (
                      <li key={res.uri}>
                        <a href={res.uri} target="_blank" rel="noreferrer">
                          {res.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </Row>
          </Col>
          <Col md={4} xs={12} className="sidebar">
            <h2>
              <Logo as="text" locale={locale} /> {labels.answers}
            </h2>
            <ol>
              {answers.map((answer, index) => (
                <li key={answer.answer.id}>
                  {answer.question.field_question_text.processed.replace(/<[^>]*>/g, "")} ("
                  {answer.answer.field_answer_text}")
                </li>
              ))}
            </ol>
            <Link
              to={`${siteData.basePath}quiz/${nextQuiz}`}
              className={`btn btn-primary w-100 mb-2 ${nextQuiz || "disabled"}`}
            >
              {labels["take-next-quiz"]}
            </Link>
            <Link to={`/${config.paths[locale].glossary}`} className="btn btn-secondary w-100 mb-2">
              {labels.glossary}
            </Link>
            <Link to={"/"} className="btn btn-secondary w-100 mb-2">
              {labels.home}
            </Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Report
